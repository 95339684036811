import { render, staticRenderFns } from "./QuestionDecription.vue?vue&type=template&id=6592b951&scoped=true&"
import script from "./QuestionDecription.vue?vue&type=script&lang=js&"
export * from "./QuestionDecription.vue?vue&type=script&lang=js&"
import style0 from "./QuestionDecription.vue?vue&type=style&index=0&id=6592b951&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6592b951",
  null
  
)

export default component.exports