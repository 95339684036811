<template>
  <div style="margin-top: 50px;">
    <leetcode-article />
  </div>
</template>

<script>
import article from './mark-down-nice'
export default {
  components: {
    'leetcode-article': article
  }
}
</script>
